const ToggleClass = require('../helpers/toggleClass');
/**
 * Dropdown component.
 * All DOM elements with classname '.js-dropdown' will open on click.
 * Clicking anywhere outside the open Dropdown will close it.
 *
 * Usage:
 * Call dropdown.init() to initialize all $dropdowns.
 *
 * Methods:
 *
 * init(): attaches eventlisteners to all $dropdowns
 * close(): closes all $dropdowns
 */

const $target = document.querySelectorAll('.js-dropdown');

const closeDropdowns = $t => {
	const $dropdowns = $t || $target;
	$dropdowns.forEach($el => {
		$el.classList.remove('is-active');
	});
};

const dropdown = $t => {
	const $dropdowns = $t || $target;
	if ($dropdowns.length > 0) {
		$dropdowns.forEach($el => {
			$el.addEventListener('click', e => {
				e.stopPropagation();
				// ensure that only one dropdown is open at a time.
				if (!$el.classList.contains('is-active')) closeDropdowns($dropdowns);
				ToggleClass($el);
			});
		});

		document.addEventListener('click', e => {
			closeDropdowns($dropdowns);
		});
	}
};

module.exports = {
	init: dropdown,
	close: closeDropdowns
};
