const $ = require('jquery');

const floatingLabels = function($target) {
	$('.js-float-label .input, .js-float-label .textarea', $target).on({
		focus() {			
			$(this)
				.closest('.js-float-label')
				.addClass('is-active');
		},
		focusout() {
			if (!$(this).val() && !$(this).attr('placeholder')) {
				$(this)
					.closest('.js-float-label')
					.removeClass('is-active');
			}
		}
	});

	function checkAll() {
		$('.js-float-label:not(.is-active)', $target).each(function() {
			const $this = $(this);
			if ($('.input, .textarea', $this).val()) {
				$this.addClass('is-active');
			}

			if ($('.input', $this).attr('placeholder')) {
				$this.addClass('is-active');
			}

			// Webkit autocompletes but doesn't actually change the val. So whe check for :-webkit-autofill via css animation-name.
			$(window).on('load', () => {
				if ($('.input, .textarea', $this).css('animation-name') == 'cancelAutofill') {
					$this.addClass('is-active');
				}
			});
		});
	}

	checkAll();
};

module.exports = floatingLabels;
