/* eslint-disable */

// Where data is saved on the first level by ID (data-componentID), on the second level by LABEL (data-localise) and on the third level by TOKEN.
var savedData = {};
// Endpoint where data is fetched from
var endpoint = './../assets/toolkit/mockdata/poc/localiser/';

function decodeEscapedHTML(encoded) {
	var parser = new DOMParser();
	var decoded = parser.parseFromString(encoded, 'text/html');
	return decoded.body.innerText;
}

function getDataForKey(component, key) {
	if (savedData[component][key] !== undefined) {
		return decodeEscapedHTML(savedData[component][key]);
	} else {
		console.warn('No value for', key, 'in', savedData[component]);
		return '';
	}
}

function saveData(data) {
	Object.keys(data).forEach(function(key) {
		saveDataForKeyWithValue(key, data[key]);
	});
}

function saveDataForKeyWithValue(key, value) {
	if (savedData[key] !== undefined) {
		console.warn('saveDataForKeyWithValue', key, 'exists');
	}
	savedData[key] = value;
}

// Find & replace for Inline Replacing of tokens
function escapeRegExp(str) {
	return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}

function replaceAll(str, find, replace) {
	return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

function localiseAll() {
	var components = document.querySelectorAll('[data-componentID]');

	components.forEach(function(el) {
		localise(el.dataset.componentid);
	});
}

// Localise with data:
// `data-localise` expects an HTML escaped string to replace the content with
var localise = function(componentid, callback) {
	var id = componentid;
	var component = document.querySelector('[data-componentID=' + componentid + ']');
	var allContentWrappers = component.querySelectorAll('[data-localise]');
	var contentWrappers = [];
	// Makes sure we don't get any child data-componentID's tokens
	for (var i = 0; i < allContentWrappers.length; i++) {
		if (
			$(allContentWrappers[i])
				.closest('[data-componentID]')[0]
				.getAttribute('data-componentID') == componentid
		) {
			contentWrappers.push(allContentWrappers[i]);
		}
	}

	// Replace Labels
	var labels = [];
	var replaceLabels = function() {
		var tokensInComponent = [];
		for (var i = 0; i < contentWrappers.length; i++) {
			var wrapper = contentWrappers[i],
				key = wrapper.getAttribute('data-localise');

			// save appropriate value for key
			var data = getDataForKey(id, key);
			tokensInComponent.push(data);

			// Replace Tokens
			if (data.indexOf('{{') != -1) {
				const tokens = savedData[id].TOKENS;
				if (typeof callback === 'function') {
					callback(key, wrapper, data, tokens);
				}
			} else {
				wrapper.innerHTML = data;
			}
		}
	};

	// Gather all labels that need replacing. this is passed to retrieveDataFromService();
	contentWrappers.forEach(function(el) {
		labels.push(el.dataset.localise);
	});

	if (!savedData[id]) {
		var dataRequest = { [id]: labels, type: 'labels' };

		retrieveDataFromService(dataRequest, function(responseText) {
			savedData[id] = JSON.parse(responseText);

			if (!savedData[id].TOKENS) {
				dataRequest = { [id]: labels, type: 'tokens' };

				retrieveDataFromService(dataRequest, function(responseText) {
					savedData[id].TOKENS = JSON.parse(responseText);
					replaceLabels();
				});
			} else {
				replaceLabels();
			}
		});
	} else {
		// already have it, just replace
		replaceLabels();
	}
};

function getRetrieve(...args) {
	retrieveDataFromService(...args);
}

function setRetrieve(func) {
	retrieveDataFromService = func;
}

function retrieveDataFromService(data, callback) {
	console.log('DEBUG MODE: retrieving json from filesystem');
	var component = Object.keys(data)[0];
	var file = data.type + '-' + component + '.json';

	// debug: force custom result for demo purposes
	if (data[component] == 'CUSTOM') file = 'data-1.json';

	var xhr = new XMLHttpRequest();
	xhr.onreadystatechange = function() {
		if (xhr.readyState == 4 && xhr.status == 200) {
			callback(xhr.responseText);
		}
	};
	xhr.open('GET', endpoint + file, true);
	xhr.send(null);
}

var updateToken = function(id, token, json) {
	Object.keys(json).forEach(function(key) {
		savedData[id].TOKENS[token][key] = json[key];
	});
};

var localiseKey = function(id, key) {
	var wrapper = document.querySelector('[data-localise=' + key + ']'),
		key = wrapper.dataset.localise;

	if (savedData[id][key] !== undefined) {
		var data = getDataForKey(id, key);
		wrapper.innerHTML = data;
	} else {
		var requestData = { [id]: key, type: 'labels' };

		retrieveDataFromService(requestData, function(responseText) {
			var json = JSON.parse(responseText);
			savedData[id][key] = json[key];

			var wrapper = document.querySelector(
				'[data-componentID=' + id + '] [data-localise=' + key + ']'
			);
			wrapper.innerHTML = getDataForKey(id, key);
		});
	}
};

var localiser = function() {
	console.log('localiser loaded');
	localiseAll();
};

module.exports = {
	init: localiser,
	// updateToken: updateToken,
	localiseAll: localiseAll,
	localise: localise,
	setRetrieve: setRetrieve,
	localiseKey: localiseKey,
	savedData: savedData
};

// HOW TO OVERWRITE retrieveDataFromService:
// From an external function, pass a new function through Localiser.setRetrieve(), ie
// Localiser.setRetrieve(function(data) { return data }));
// Now whenever retrieveDataFromService() is called it will return data instead of doing the default ajax call.
// thats it@
