
const init = function() {	
	const item = document.querySelectorAll('.collapser-header');

	for(var i = 0; i < item.length; i++){
		item[i].addEventListener('click', function(){
			var collapser = this.closest('.collapser-block');
		 	if (collapser.classList.contains('is-active')) {
		 		collapser.classList.remove('is-active');
		 	} else{ 
		 		collapser.classList.add('is-active');
		 	}
		});
	}
}

module.exports = {
	init
};
