const Mask = require('../helpers/mask');
const ToggleClass = require('../helpers/toggleClass');
/**
 * Modal component.
 * All DOM elements with classname '.js-modal' will open their target on click.
 *
 * Usage:
 * Call modal.init() to initialize all modals.
 *
 * Methods:
 *
 * init(): attaches eventlisteners to all modals
 * initFor(): attaches eventlisteners to one modal
 * toggleModal(): toggles modal programmatically by element
 * triggerModal(): toggles modal programmatically by selector
 */

const $trigger = document.querySelectorAll('.js-modal');

var lastModal = null;

const checkIfModalShouldBeActive = function() {
	if (!lastModal) {
		return true;
	}

	if (
		(window.location.hash == '#modal-static' || window.location.hash == '#/modal-static') && 
		!lastModal.classList.contains('is-active')) {
		$('html').attr('data-show-modal', 1);
		toggleModal(lastModal);
	} else if (window.location.hash != '#modal-static' && 
				window.location.hash != '#/modal-static' && 
				lastModal.classList.contains('is-active')) {
		$(lastModal).removeClass('is-active');
		window.history.replaceState({ modal: true }, '', '#'); 
		$('html').attr('data-show-modal', 0);
		Mask.toggleFor('has-modal-background', false);
	}
}

window.addEventListener('hashchange', checkIfModalShouldBeActive, false);

const toggleModal = $target => { 
	if ($target) {
		lastModal = $target;
	}

	// move to the body element to break out of z-indexed elements
	$($target).appendTo('body');

	ToggleClass($target);
	const isVisible = $target.classList.contains('is-active');

	if (isVisible) {
		if ($('html').attr('data-show-modal') != 1) {
			$('html').attr('data-show-modal', 1);
		    window.history.pushState({ modal: true }, '', '#modal-static');
		}
	} else {
		window.history.replaceState({ modal: true }, '', '#'); 
		$('html').attr('data-show-modal', 0);
	}

	Mask.toggleFor('has-modal-background', isVisible);
};

const triggerModal = selector => {
	const $target = document.querySelector(selector);
	toggleModal($target);
};

const initFor = $el => {
	const selector = $el.getAttribute('data-modal-selector');

	$(document)
		.on(
			'click', 
			'.modal .box, .modal .modal-card', 
			e => e.stopPropagation()
		).on(
			'click', 
			'.modal:not(.dialog)', 
			e => {
				$('.modal').removeClass('is-active');
				$('html').attr('data-show-modal', 0);
				window.history.replaceState({ modal: true }, '', '#'); 

				Mask.toggleFor('has-modal-background', false);
			}
		);
 
	$el.addEventListener('click', e => {
		e.stopPropagation();
		const $target = document.querySelector(selector);
		toggleModal($target);
	});

};

const init = () => {
	if ($trigger.length > 0) {
		Mask.registerFor('has-modal-background');
		$trigger.forEach($el => {
			initFor($el);
		});
	}
};

module.exports = {
	init,
	initFor,
	toggleModal,
	triggerModal
};
