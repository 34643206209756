/**
 * The toastbar takes into account several use cases
 * - The toast simply needs to be hidden by the user.
 * - The toast allows the user to make a choice, ie 'yes` and `no`. This allows from 1 to an unlimited amount of choices.
 * Parent Attributes
 * - `data-rel` (required) the unique key which the child kan refer to, generally the id
 * - `data-cookie-name` (optional) a custom cookie name, defaults to `data-rel`s content
 * Child Attributes
 * - `data-target-selector` (required) the unique key of the parent, generally the parent's `data-rel` content
 * - `data-cookie-value` (optional) the value of the cookie to be set, defaults to `seen`. Can be used for multiple choices, ie `yes` and `no`.
 */
const $ = require('jquery');
const dismissHelper = require('../helpers/dismiss');
const localStorageHelper = require('../helpers/localStorage');

const toastbar = function() {
	const cookieGroup = 'toastbars';
	$('.js-toastbar').each(function() {
		const cookieName = $(this).data('cookie-name') || $(this).data('rel');
		const cookieItem = localStorageHelper.get(cookieName, cookieGroup);

		if (cookieItem) {
			$(this).remove();
		} else {
			$(this).show();
		}
	});

	$('.js-toastbar .delete').on('click', function() {
		const targetSelector = $(this).data('target-selector');
		const cookieName = $(targetSelector).data('cookie-name') || $(targetSelector).data('rel');
		const cookieValue = $(this).data('cookie-value') || 'seen';
		localStorageHelper.set(cookieValue, cookieName, cookieGroup);
		dismissHelper(this);
	});
};

module.exports = toastbar;
