/* eslint-disable */

require('chart.js');

var charts = function() {
	var barChart = function() {
		var el = document.getElementById('barChart');
		if (el == undefined) {
			return;
		}
		new Chart(el, {
			type: 'bar',
			data: {
				labels: ['Attention', 'Info', 'Default', 'Brand', 'Warning'],
				datasets: [
					{
						label: '# of Votes',
						data: [12, 19, 3, 5, 3],
						backgroundColor: [
							'rgba(239, 80, 71, 0.8)',
							'rgba(209, 237, 247, 0.8)',
							'rgba(250, 196, 94, 0.8)',
							'rgba(139, 194, 65, 0.8)',
							'rgba(255, 129, 60, 0.8)'
						],
						borderColor: [
							'rgba(239, 80, 71, 1)',
							'rgba(209, 237, 247, 1)',
							'rgba(250, 196, 94, 1)',
							'rgba(139, 194, 65, 1)',
							'rgba(255, 129, 60, 1)'
						],
						borderWidth: 1
					}
				]
			},
			options: {
				scales: {
					yAxes: [
						{
							ticks: {
								beginAtZero: true
							}
						}
					]
				}
			}
		});
	};

	barChart();

	var pieChart = function() {
		var el = document.getElementById('pieChart');
		if (el == undefined) {
			return;
		}
		new Chart(el, {
			type: 'pie',
			data: {
				labels: ['Attention', 'Info', 'Default', 'Brand', 'Warning'],
				datasets: [
					{
						label: '# of Votes',
						data: [12, 19, 3, 5, 3],
						backgroundColor: [
							'rgba(239, 80, 71, 0.8)',
							'rgba(209, 237, 247, 0.8)',
							'rgba(250, 196, 94, 0.8)',
							'rgba(139, 194, 65, 0.8)',
							'rgba(255, 129, 60, 0.8)'
						],
						borderColor: [
							'rgba(239, 80, 71, 1)',
							'rgba(209, 237, 247, 1)',
							'rgba(250, 196, 94, 1)',
							'rgba(139, 194, 65, 1)',
							'rgba(255, 129, 60, 1)'
						],
						borderWidth: 1
					}
				]
			},
			options: {
				scales: {
					yAxes: [
						{
							ticks: {
								beginAtZero: true
							}
						}
					]
				}
			}
		});
	};

	pieChart();

	var lineChart = function() {
		var el = document.getElementById('lineChart');
		if (el == undefined) {
			return;
		}
		new Chart(el, {
			type: 'line',
			data: {
				labels: ['Attention', 'Info', 'Default', 'Brand', 'Warning'],
				datasets: [
					{
						label: '# of Votes',
						data: [12, 19, 3, 5, 3],
						backgroundColor: [
							'rgba(239, 80, 71, 0.8)',
							'rgba(209, 237, 247, 0.8)',
							'rgba(250, 196, 94, 0.8)',
							'rgba(139, 194, 65, 0.8)',
							'rgba(255, 129, 60, 0.8)'
						],
						borderColor: [
							'rgba(255,99,132,1)',
							'rgba(54, 162, 235, 1)',
							'rgba(255, 206, 86, 1)',
							'rgba(75, 192, 192, 1)',
							'rgba(255, 159, 64, 1)'
						],
						borderWidth: 1
					}
				]
			},
			options: {
				scales: {
					yAxes: [
						{
							ticks: {
								beginAtZero: true
							}
						}
					]
				}
			}
		});
	};

	lineChart();

	var horizontalBar = function() {
		var el = document.getElementById('horizontalBar');
		if (el == undefined) {
			return;
		}
		new Chart(el, {
			type: 'horizontalBar',
			data: {
				labels: ['Attention', 'Info', 'Default', 'Brand', 'Warning'],
				datasets: [
					{
						label: '# of Votes',
						data: [12, 19, 3, 5, 3],
						backgroundColor: [
							'rgba(239, 80, 71, 0.8)',
							'rgba(209, 237, 247, 0.8)',
							'rgba(250, 196, 94, 0.8)',
							'rgba(139, 194, 65, 0.8)',
							'rgba(255, 129, 60, 0.8)'
						],
						borderColor: [
							'rgba(239, 80, 71, 1)',
							'rgba(209, 237, 247, 1)',
							'rgba(250, 196, 94, 1)',
							'rgba(139, 194, 65, 1)',
							'rgba(255, 129, 60, 1)'
						],
						borderWidth: 1
					}
				]
			},
			options: {
				scales: {
					yAxes: [
						{
							ticks: {
								beginAtZero: true
							}
						}
					]
				}
			}
		});
	};

	horizontalBar();

	var stackedBar = function() {
		var el = document.getElementById('stackedBar');
		if (el == undefined) {
			return;
		}
		new Chart(el, {
			type: 'bar',
			data: {
				labels: ['Attention', 'Info', 'Default', 'Brand', 'Warning'],
				datasets: [
					{
						label: 'Attention',
						data: [12],
						backgroundColor: 'rgba(239, 80, 71, 0.8)'
					},
					{
						label: 'Info',
						data: [19],
						backgroundColor: 'rgba(209, 237, 247, 0.8)'
					},
					{
						label: 'Default',
						data: [3],
						backgroundColor: 'rgba(250, 196, 94, 0.8)'
					},
					{
						label: 'Brand',
						data: [5],
						backgroundColor: 'rgba(139, 194, 65, 0.8)'
					},
					{
						label: 'Warning',
						data: [3],
						backgroundColor: 'rgba(255, 129, 60, 0.8)'
					}
				]
			},
			options: {
				scales: {
					xAxes: [{ stacked: true }],
					yAxes: [{ stacked: true }]
				}
			}
		});
	};

	stackedBar();
};

module.exports = charts;
