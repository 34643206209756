const $ = require('jquery');

const showPassword = function() {
	$('.js-showPassword').each(function() {
		const $this = $(this);
		const $parent = $this.closest('.field');
		const $field = $('[type=password]', $parent);

		const icon_on = 'icon-eye-open';
		const icon_off = 'icon-eye-closed';

		$this.on('click', e => {
			e.preventDefault; // TODO: This is not executed bc it's not called ( e.preventDefault() ). Check if it still works if this is executed.

			if ($field.attr('type') == 'password') {
				$field.attr('type', 'text');
				$('.icon', $this).removeClass(icon_off);
				$('.icon', $this).addClass(icon_on);
			} else {
				$field.attr('type', 'password');
				$('.icon', $this).removeClass(icon_on);
				$('.icon', $this).addClass(icon_off);
			}
		});
	});
};

module.exports = showPassword;
