const $ = require('jquery');
require('jquery-validation');
require('jquery-validation-unobtrusive');
const IBAN = require('iban');

const generateValidationAttributes = function(validation) {
	const retval = {};
	if (validation) {
		if (validation.validationMessage) retval['data-msg'] = validation.validationMessage;
		if (validation.required) {
			retval['data-rule-required'] = true;
			retval['data-msg-required'] = validation.required;
		}
		if (validation.pattern) {
			if (validation.pattern.rule) retval['data-rule-regex'] = validation.pattern.rule;
			if (validation.pattern.message) retval['data-msg-regex'] = validation.pattern.message;
		}
		if (validation.equalTo) {
			if (validation.equalTo.rule) retval['data-rule-equalto'] = validation.equalTo.rule;
			if (validation.equalTo.message) retval['data-msg-equalto'] = validation.equalTo.message;
		} 
		if (validation.min) {
			if (validation.min.rule) retval['min'] = validation.min.rule;
			if (validation.min.message) retval['data-msg-min'] = validation.min.message;
		}
		if (validation.max) {
			if (validation.max.rule) retval['max'] = validation.max.rule;
			if (validation.max.message) retval['data-msg-max'] = validation.max.message;
		}
		if (validation.date) {
			retval.min = validation.date.min.rule
				.split('-')
				.reverse()
				.join('-');

			retval['data-rule-dateMin'] = validation.date.min.rule;
			retval['data-msg-dateMin'] = validation.date.min.message;
			retval['data-msg-min'] = validation.date.min.message; // mobile browser gebruikt de validatie van min en max ipv dateMin en dateMax

			retval.max = validation.date.max.rule
				.split('-')
				.reverse()
				.join('-');
			retval['data-rule-dateMax'] = validation.date.max.rule;
			retval['data-msg-dateMax'] = validation.date.max.message;
			retval['data-msg-max'] = validation.date.max.message; // mobile browser gebruikt de validatie van min en max ipv dateMin en dateMax

			retval['data-rule-date'] = true;
			retval['data-msg-date'] = validation.date.message;
		}
		if (validation.rules) {
			for (let i = 0; i < validation.rules.length; i += 1) {
				const { key, rule, message } = validation.rules[i];
				if (rule) retval[`data-rule-${key}`] = rule;
				if (message) retval[`data-msg-${key}`] = message;
			}
		}
		if (validation.iban) {
			retval['iban'] = 1;

			if (validation.iban.message) retval['data-msg-iban'] = validation.iban.message;
		}

		if (validation.email) {
			retval['email'] = 1;

			if (validation.email.message) retval['data-msg-email'] = validation.email.message;
		}
	}
	return retval;
};

const formValidation = function(form) {
	// Source: "Wait for User to Stop Typing, Using JavaScript | Gregory Schier" https://schier.co/blog/2014/12/08/wait-for-user-to-stop-typing-using-javascript.html

	const that = this;
	let inputValidationTimeout = null;
	const inputValidationKeyupFunction = function(element, event, autocomplete) {
		const keycode = event.keyCode ? event.keyCode : event.charCode;

		// ignore all special keys except backspace
		if (
			(keycode >= 48 && keycode <= 90) ||
			(keycode >= 96 && keycode <= 111) ||
			(keycode >= 186 && keycode <= 192) ||
			(keycode >= 219 && keycode <= 222) ||
			keycode == 8 ||
			keycode == 46 ||
			autocomplete
		) {
			clearTimeout(inputValidationTimeout);

			inputValidationTimeout = setTimeout(() => {
				checkPasswordStrength(element);
				$(element).valid();
            }, autocomplete === true ? 0 : 500); //automplete and !keycode is triggered by backspace
		}
	};

	const $form = $(form).length > 0 ? $(form) : $('.js-validate');
	$form.each(function() {
		$.validator.addMethod('atLeastOneLowercaseLetter', function(value, element) {
			return this.optional(element) || /[a-z]+/.test(value);
		});

		$.validator.addMethod('atLeastOneUppercaseLetter', function(value, element) {
			return this.optional(element) || /[A-Z]+/.test(value);
		});
		$.validator.addMethod('max', function(value, element) {
			if (element.type == 'date' || value.indexOf('-') != '-1') {
				return true;
			}

			if (isNaN(value * 1)) {
				return false;
			}

			var $el = $(element);
			var normalized = value * 1;

			if ($el.prop('max') * 1 < normalized) {
				normalized = value.slice(0, $el.prop('max').toString().length);
			}

			if (normalized.toString() != value) {
				$el.val(normalized);
			}

			return true;
		});
		$.validator.addMethod('dateMax', function(value, element) {
			const maxDate = that.validateDate($(element).attr('data-rule-dateMax'));
			const curDate = that.validateDate(value, element.type);

			return this.optional(element) || curDate <= maxDate;
		});
		$.validator.addMethod('dateMin', function(value, element) {
			const minDate = that.validateDate($(element).attr('data-rule-dateMin'));
			const curDate = that.validateDate(value, element.type);

			return this.optional(element) || curDate >= minDate;
		});
		$.validator.addMethod('date', function(value, element) { 
			const date = that.validateDate(value, element.type);

			const isValidDate = !!date;
 
 			var $el = $(element);
 			$el.unbind('blur')
			
			// if date is valid, check if the date is in the right format
			if (isValidDate && element.type !== 'date') {
				var niceDate = 
					((date.getDate()+"").length == 1 ? '0' : '') + date.getDate() + '-' + 
					(((date.getMonth() + 1) + "").length == 1 ? '0' : '') + (date.getMonth() + 1) + '-' + 
					date.getFullYear();

				if (niceDate != value && element && !$el.is(":focus")) {
					// do not disturb user and only set right when unfocus the input
					$el.on('blur', function() { $el.val(niceDate); });
				}
			}

			return this.optional(element) || isValidDate;
		});
		$.validator.addMethod('iban', function(value, element) {
			return this.optional(element) || IBAN.isValid(value);
		});

		$.validator.addMethod('email', function(value, element) {
			return this.optional(element) || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value);
		});
		$(this).validate({
			highlight(element, errorClass, validClass) {
				$(element)
					.closest('.field')
					.addClass('is-error')
					.removeClass('is-success');
			},
			unhighlight(element, errorClass, validClass) {
				$(element)
					.closest('.field')
					.removeClass('is-error')
					.addClass('is-success');
			},
			ignore: '.js-skip-validation',
			errorElement: 'span',
			errorClass: 'help',
			onkeyup: inputValidationKeyupFunction,
			// eslint-disable-next-line object-shorthand
			errorPlacement: function(error, element) {
				error.insertAfter(element.parent());
			}
		});

		// Triggers when input was autocmpleted
		$('.input:not(.js-skip-validation)', $(this)).each(function() {
			const $this = $(this);
			$this.on('input', function(event) {

				// don't do anything with InputEvent - only allow other events to be continued
				// Datepicker on hide fires an 'Event'. KeyboardEvent comes from users keyboard.
				// We only need to validate Event and KeyboardEvent
				if (!event.originalEvent.data && !(event.originalEvent instanceof InputEvent)) {
					// this is only empty if autocomplete was fired
					inputValidationKeyupFunction(this, event, true);
				}
			});
		});
	});

	const checkPasswordStrength = function(element) {
		if ($(element).hasClass('js-password-strength')) {
			const upperCase = new RegExp('[A-Z]');
			const lowerCase = new RegExp('[a-z]');
			const pw = $(element).val();
			const $passwordHelp = $($('.is-password-strength'), $(element).closest('.field'));

			if (pw.match(upperCase)) {
				$('.password-uppercase .icon', $passwordHelp)
					.addClass('has-text-brand icon-checkmark')
					.removeClass('icon-bullet');
			} else {
				$('.password-uppercase .icon', $passwordHelp)
					.removeClass('has-text-brand icon-checkmark')
					.addClass('icon-bullet');
			}
			if (pw.match(lowerCase)) {
				$('.password-lowercase .icon', $passwordHelp)
					.addClass('has-text-brand icon-checkmark')
					.removeClass('icon-bullet');
			} else {
				$('.password-lowercase .icon', $passwordHelp)
					.removeClass('has-text-brand icon-checkmark')
					.addClass('icon-bullet');
			}
			if (pw.length >= 8) {
				$('.password-length .icon', $passwordHelp)
					.addClass('has-text-brand icon-checkmark')
					.removeClass('icon-bullet');
			} else {
				$('.password-length .icon', $passwordHelp)
					.removeClass('has-text-brand icon-checkmark')
					.addClass('icon-bullet');
			}
		}
	};

	// Converts European date format to javascript date format and checks if it's a valid date.
	// If so returns the new date.
	this.validateDate = function(date, type) {
		return getFixedDate(date, type === 'date');
	};
};

const getFixedDate = (date, hasYearFirst) => {   
	// format ddmmyyyy to ddmmyyyy
	if (date.length == '8' && date.indexOf('-') === -1) {
		date = date.slice(0, hasYearFirst ? 4 : 2) + '-' + 
				date.slice(hasYearFirst ? 4 : 2, hasYearFirst ? 6 : 4) + '-' + 
				date.slice(hasYearFirst ? 6 : 4);
	}

	const tmpDate = date.match(/\d+/g);

	if (tmpDate == null) {
		return undefined;
	}

	const day = tmpDate[hasYearFirst ? 2 : 0];
	const month = tmpDate[1];
	const year = tmpDate[hasYearFirst ? 0 : 2];

	const fixedDate = new Date(Date.UTC(year, (Number(month) + 11) % 12, day));
	fixedDate.setHours(0, 0, 0, 0);

	if (
		year != null 
		&& year.length == 4
		&& Boolean(+fixedDate) 
		&& fixedDate.getDate() == day
		&& (fixedDate.getMonth() + 1) == month
	) {
		return fixedDate;
	}

	return undefined;
};

module.exports = {
	init: formValidation,
	generateValidationAttributes,
	getFixedDate
};
