const defaults = {
  message: "Your message here",
  duration: 5000,
  position: "bottom-right",
  closeOnClick: true,
  opacity: 1
};

let initialized = false;
let containers = {};
let positions = {};
let doc = document;

function init() {
  containers = {
    /** for now, we only need the bottom-right **/
    noticesBottomRight: doc.createElement("div"),
  };

  let className = "toaster";

  containers.noticesBottomRight.classList.add(className);
  containers.noticesBottomRight.classList.add('is-bottom-right');

  positions = {
    "bottom-right": containers.noticesBottomRight
  };

  initialized = true;
}

function toast(params) {
  if (!initialized) {
    init();
  }

  let options = params;

  const toast = new Toast(options);
  const container = positions[options.position] || positions[defaults.position];

  doc.body.appendChild(container);

  container.appendChild(toast.element);
}

function setDoc(newDoc) {
  for (let key in containers) {
    let element = containers[key];
    element.parentNode.removeChild(element);
  }

  doc = newDoc;
  init();
}

class Toast {
  constructor(options) {
    this.element = doc.createElement("div");
    this.opacity = options.opacity;
    this.type = options.type;
    this.animate = options.animate;
    this.dismissible = options.dismissible;
    this.dismissibleButton = options.dismissibleButton;
    this.closeOnClick = options.closeOnClick;
    this.message = options.message;
    this.duration = options.duration ? options.duration : defaults.duration;
    this.pauseOnHover = options.pauseOnHover;

    this.element.classList.add('toast')
    this.element.classList.add('notification')
    this.element.classList.add('has-icon')
    this.element.classList.add('show')

    window.setTimeout(() => this.element.classList.remove('show'), 500);

    if (this.type) {
      this.element.classList.add(this.type);
    }

    if (this.dismissible) {
      let dismissButton = doc.createElement("button");

      dismissButton.className = "delete";

      dismissButton.addEventListener("click", () => {
        this.destroy();
      });

      this.element.insertAdjacentElement("afterbegin", dismissButton);
    } 

    if (this.closeOnClick) {
      this.element.addEventListener("click", () => {
        this.destroy();
      });
    }

    if (typeof this.message === "string") {
      this.element.insertAdjacentHTML("beforeend", this.message);
    } else {
      this.element.appendChild(this.message);
    }

    const timer = new Timer(() => {
      this.destroy();
    }, this.duration);

    if (this.pauseOnHover) {
      this.element.addEventListener("mouseover", () => {
        timer.pause();
      });

      this.element.addEventListener("mouseout", () => {
        timer.resume();
      });
    }
  }

  /**
   * Add class to element to hide it. Use css for animating.
   */
  destroy() {
    this.element.classList.add('hide');
  }
}

class Timer {
  constructor(callback, delay) {
    this.timer;
    this.start;
    this.remaining = delay;
    this.callback = callback;

    this.resume();
  }

  pause() {
    window.clearTimeout(this.timer);
    this.remaining -= new Date() - this.start;
  }

  resume() {
    this.start = new Date();
    window.clearTimeout(this.timer);
    this.timer = window.setTimeout(this.callback, this.remaining);
  }
}

export const pop = function(type, title, body, duration/*, buttons*/) {  
  if (title && body) {
    var message = '<span class="is-title">' + title + '</span>' + body;
  } else if (title) {
    var message = title;
  } else if (body) {
    var message = body;
  }

  toast({
    message: message,
    type: "is-" + type,
    dismissible: true,
    pauseOnHover: true,
    duration: duration,
    position: 'bottom-right'
  });
};
