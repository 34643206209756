const cookieMasterFileName = 'mgc';

const get = function(name, group) {
	const cookieMasterFileString = localStorage.getItem(cookieMasterFileName);
	const cookieMasterFile = JSON.parse(cookieMasterFileString) || {};

	let cookieItem = '';

	if (group) {
		const cookieGroupFile = cookieMasterFile[group] || {};
		cookieItem = cookieGroupFile[name];
	} else {
		cookieItem = cookieMasterFile[name];
	}

	return cookieItem;
};

const set = function(val, name, group) {
	const cookieMasterFileString = localStorage.getItem(cookieMasterFileName);
	/* eslint-disable-next-line */
	let cookieMasterFile = JSON.parse(cookieMasterFileString) || {};
	if (group) {
		const cookieGroupFile = cookieMasterFile[group] || {};
		cookieGroupFile[name] = val;
		cookieMasterFile[group] = cookieGroupFile;
	} else {
		cookieMasterFile[name] = val;
	}
	localStorage.setItem(cookieMasterFileName, JSON.stringify(cookieMasterFile));
};

module.exports = {
	get,
	set
};
