/**
 * Viewport Loader component.
 *
 * Usage:
 * ViewportLoader.show(message)
 *
 * Methods:
 *
 * show(): Directly show the viewport loader
 * hide(): Directly hide the viewport loader
 */

const IS_LOADING_CLASSNAME = 'is-loading';

const show = message => {
	document.body.dataset.loaderMessage = '';
	document.body.className += " " + IS_LOADING_CLASSNAME;

	if (message) {
		document.body.dataset.loaderMessage = message;
	}
};

const hide = () => { 	
	document.body.className = document.body.className.replace(IS_LOADING_CLASSNAME, '') 
};

module.exports = {
	show,
	hide 
};
