/**
 * Mask component.
 * Since several components (ie modal, siteHeader) use a mask in combination with the `is-clipped` class,
 * This avoids the `is-clipped` class being removed when other keys might still be active.
 *
 * Methods:
 *
 * registerFor(): register the mask toggler for a specific class
 * toggleFor(): toggle the mask for a specific class
 */

const keys = [];

const registerFor = function(theClass) {
	keys.push(theClass);
};

const toggleFor = function(theClass, bool) {
	const html = document.querySelector('html');
	// First toggle theClass
	if (bool) {
		html.classList.add(theClass);
	} else {
		html.classList.remove(theClass);
	}
	// If any of the registered classes are on the `html`, then leave `is-clipped`, else remove it.
	const classes = Array.from(html.classList);
	if (classes.filter(value => keys.indexOf(value) !== -1).length > 0) {
		html.classList.add('is-clipped');
	} else {
		html.classList.remove('is-clipped');
	}
};

module.exports = {
	registerFor,
	toggleFor
};
