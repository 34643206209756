const ToggleClass = require('../helpers/toggleClass');
/**
 * Toggle Active
 * Makes the toggleActive helper available through the `js-toggle-active` class.
 *
 * Usage:
 * Call toggleActive.init() to initialize all $trigger.
 *
 * Methods:
 *
 * init(): attaches eventlisteners to all $trigger
 */

const $trigger = document.querySelectorAll('.js-toggle-active');

const toggleActive = () => {
	if ($trigger.length > 0) {
		$trigger.forEach($el => {
			const selector = $el.getAttribute('data-toggle-selector');
			const $target = document.querySelectorAll(selector);

			$el.addEventListener('click', e => {
				e.stopPropagation();
				$target.forEach($t => {
					ToggleClass($t);
				});
			});
		});
	}
};

module.exports = {
	init: toggleActive
};
