const $ = require('jquery');

var gcscroll = to => {
	if (typeof to == 'number') {
		return scrollToPosition(to);
	}

	if (typeof to == 'string') {
		return scrollToElement(to);
	}

	if (typeof to == 'object') {
		return scrollToElement(to);
	}

	scrollToPosition(0);
}

var scrollToElement = identifier => scrollToPosition($(identifier).offset().top);

var scrollToPosition = pos => 
	$([document.documentElement, document.body]).animate({
        scrollTop: pos
    }, 200);
			
$(document).on('router-page-change', e => scrollToPosition(0));

module.exports = gcscroll;
