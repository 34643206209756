const Promise = require('promise-polyfill').default;

/**
 * Dialogue with the user with the dialog-object
 *
 * Usage:
 * Dialog.ask(title, question, [options])
 * Dialog.tell(title, message, [buttonlabel]);
 *
 * question/message could be html
 *
 * Returns: Promise object. Triggered when user is pressing one of the options, 
 * the value is the value of success-property;
 * 
 * Options:
 * [
 *   {
 *   	label: 'Failure label',
 *   	success: false,
 *   },
 *   {
 *   	label: 'Success label',
 *   	success: true,
 *   	url: '[url]',
 *   	target: '[target]'
 *   },
 * ]
 * 
 * Options are shown to the user, with the label. Success is telling the promise-object 
 * how to resolve. If you give an url as property, the url will be opened when 
 * clicking on the option. 
 * 
 * examples:
 * 
Dialog.ask(
	"Verhuizen", 
	'Er is reeds een verhuizing in gang gezet. Wat wil je doen?', 
	[
		{label: 'Verhuizing annuleren', success: false}, 
		{label: 'Doorgaan met verhuizen', success: true}
	]
).then(success => alert('User said: ' + (success ? 'Positive' : 'Negative')));

Dialog.tell('Verhuizen', 'Bericht', 'Probeer nog een keer').then(() => { 
	alert('User pressed button');
});
 */

var lastModal = null;

const checkIfModalShouldBeActive = function() {
	if (!lastModal) {
		return true;
	}

	if (
		(window.location.hash == '#modal' || window.location.hash == '#/modal') && 
		!lastModal.hasClass('is-active')) {
		$('html').attr('data-show-modal', 1);
		$(lastModal).addClass('is-active');
				
		$('html').addClass('has-modal-background');
	} else if (window.location.hash != '#modal' && 
				window.location.hash != '#/modal' && 
				lastModal.hasClass('is-active')) {
		$('html').attr('data-show-modal', 0);
		$(lastModal).removeClass('is-active');
				
		$('html').removeClass('has-modal-background');
	}
}

window.addEventListener('hashchange', checkIfModalShouldBeActive, false);

const id = 'gc-custom-triggered-dialog';

var showDialog = (title, body, options, resolve, reject) => { 
	$('html').addClass('has-modal-background');

	var modal = $('<div>')
					.addClass('modal dialog is-active')
					.prop('id', id);
	lastModal = modal;

	var p_title = $('<header>')
						.addClass('modal-card-head')
						.append(
							$('<p>').addClass('modal-card-title').text(title ? title : 'Informatie')
						);

	var a_body = $('<article>').addClass('modal-card-body content').html(body);

	var f_options = $('<footer>').addClass('modal-card-foot');

	if (options) {
	    for (i in options) {
	    	var option = options[i];

			f_options.append(
				$('<a>')
					.addClass('modal-card-foot-item modal-card-foot-' + 
						(option['success'] ? 'continue' : 'cancel') + 
						' js-modal'
					)
					.attr('aria-label', 'close')
					.text(option['label'])
					.attr('data-is-success', option['success'])
					.attr('href', option['url'] ? option['url'] : undefined)
					.attr('target', option['target'] ? option['target'] : undefined)
					.on('click', function() { 
						modal.removeClass('is-active'); 
				   		$('html').removeClass('has-modal-background');

				   		if (resolve) {
				   			resolve($(this).data('is-success'));
				   		}
					})
		 	);
		}
	} 

	return modal
		.append(
			[
				$('<div>')
					.addClass('modal-card')
					.append( 
						p_title,
						a_body,
						f_options
					)
					.on('click', e => e.stopPropagation()),
			]
		)
};

const ask = (title, question, options) => {
	$('html').attr('data-show-modal', 1);
    window.history.pushState({ modal: true }, '', '#modal');

	return new Promise(function(resolve, reject) {
		dialog = showDialog(title, question, options, resolve, reject);

		$('body').append(dialog);
	}).then((success) => { 
		window.history.replaceState({ modal: true }, '', '#'); 
		$('html').attr('data-show-modal', 0);

		return success; 
	});
};

const tell = (title, message, label) => { 	
	return ask(title, message, [{ label: label ? label : 'Doorgaan', success: true }]);
};

module.exports = {
	ask,
	tell 
};
