/**
 * The toolkit.js is run inside the toolkit.
 * It can have extra code and scripts to facilitate the toolkit.
 *
 * It is not meant to be included in applications.
 */



require('./components/localiser.js');
require('./helpers/svg4everybody.js');
require('./helpers/polyfills.js');

const FormValidation = require('./helpers/form-validation.js');
const toggleActive = require('./components/toggleActive.js');
const Dropdown = require('./components/dropdown.js');
const showPassword = require('./helpers/showPassword.js');
const debounceItems = require('./helpers/debounceItems.js');
const charts = require('./components/charts.js');
const floatingLabels = require('./helpers/floatingLabels.js');
const Calendar = require('./components/toggleCalendar.js');
const toastbar = require('./components/toastbar.js');
const Modal = require('./components/toggleModal.js');
const Flow = require('./structures/toggleFlow.js');
const Collapser = require('./components/directCollapser.js');

window.Toaster = require('./components/toaster.js');
window.ViewportLoader = require('./components/toggleViewportLoader.js');
window.Dialog = require('./components/directModal.js');
window.gcscroll = require('./helpers/gcscroll.js');

toggleActive.init();
Dropdown.init();
FormValidation.init();
showPassword();
debounceItems();
charts();
toastbar();
Modal.init();
floatingLabels();

Flow.init();
Calendar.init();
Collapser.init();
