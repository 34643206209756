// General Function to Toggle active class on any element
/** ****
 *
 * Class to toggle defaults to 'is-active'
 *
 */

const toggler = (target, newClass) => {
	let $target = [];
	const className = newClass || 'is-active';

	// check if var target is a selector or DOM element.
	if (target && target.nodeType) {
		$target.push(target);
	} else {
		$target = document.querySelectorAll(target);
	}

	$target.forEach($el => {
		$el.classList.toggle(className);
	});
};

module.exports = toggler;
