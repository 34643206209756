const $ = require('jquery');
const bulmaCalendar = require('bulma-calendar');

/**
 * Calendar component.
 * All DOM elements with classname '.js-calendar' will open on click.
 * Clicking anywhere outside the open Calendar will close all calendars.
 *
 * Attributes:
 * - data-triggers: selector for elements that can trigger the datepicker
 *
 * Usage:
 * Call calendar.init() to initialize all calendars.
 *
 * Methods:
 *
 * init(): inits and attaches eventlisteners to all calendars
 * initWith(): inits and attaches eventlisteners to single element
 */

const selector = '.js-datepicker';
const options = {
	toggleOnInputClick: false,
	showHeader: false,
	showFooter: false,
	weekStart: 1,
	dateFormat: 'DD-MM-YYYY',
	lang: 'nl',
	startDate: new Date()
};
const datepickers = [];
const initWith = function(element) {
	if (!element) return;
	const parent = element.closest('.field');
	const triggers = parent.querySelectorAll(element.dataset.triggers);
	// Check for mobile. Bulma calendar support on desktop, native calendar support on mobile
	if ('ontouchstart' in document.documentElement) {
		element.type = 'date';
		return;
	}
	element.type = 'text';

	// add min and max dates if needed
	if (element.hasAttribute('min')) {
		const minDate = element
			.getAttribute('min')
			.split('-')
			.reverse()
			.join('-');
		options.minDate = minDate;
	}
	if (element.hasAttribute('max')) {
		const maxDate = element
			.getAttribute('max')
			.split('-')
			.reverse()
			.join('-');
		options.maxDate = maxDate;
	}

	// Init datepicker
	const datepicker = bulmaCalendar.attach(element, options)[0];

	// Handle triggers
	triggers.forEach(el => {
		el.addEventListener('click', e => {
			e.stopPropagation();
			if (datepicker.isOpen()) {
				datepicker.hide();
			} else {
				datepicker.show();
			}
		});
	});


	datepicker.on('hide', e => { 
		// check if js-validate is done on the form around the datepicker. If so, 
		// date-input could be validated through .valid()
		if(element.form && $(element.form).data('validator')){  
			$(element).valid();
		}

		// trigger event to trigger vuejs to store the new value
		if ("createEvent" in document) {
		    var evt = document.createEvent("HTMLEvents");
		    evt.initEvent("input", false, true);
		    element.dispatchEvent(evt);
		}
		else {
		    element.fireEvent("oninput");
		}
	});

	// Add to datepickers array
	datepickers.push(datepicker);

	// Hides datepickers if clicked outside of open datepickers
	document.addEventListener('click', e => {
		if ('ontouchstart' in document.documentElement) return;
		for (let i = 0; i < datepickers.length; i += 1) {
			if (datepickers[i].isOpen()) {
				datepickers[i].hide();
			}
		}
	});
};

const init = function() {
	const elements = document.querySelectorAll(selector);

	elements.forEach(element => {
		initWith(element);
	});
};

module.exports = {
	init,
	initWith
};
