const $ = require('jquery');
/**
 * Flow component.
 * All DOM elements with classname '.js-flow' will initialize.
 *
 * Usage:
 * Call flow.init() to initialize all $flows.
 *
 * Methods:
 *
 * init(): attaches eventlisteners to all $flows
 * initWith(): attaches eventlisteners to element
 */

const $target = document.querySelectorAll('.js-flow');

const initWith = $t => {
	const $carouselContent = $('.carousel-content', $t);
	const $items = $('.carousel-item', $t);

	if ($carouselContent.length > 0) {
		/**
		 * toggleReverse change class of .carousel-content element
		 * @param check {bool} compare if .carousel-content element contains .carousel-reverse
		 * @param action {string} [add, remove]
		 */
		const toggleReverse = function(check, action) {
			const jAction = `${action}Class`;
			if ($carouselContent.hasClass('carousel-reverse') == check) {
				$carouselContent[jAction]('carousel-reverse');
			}
		};
		/**
		 * toggleAnimate add or remove .carousel-animate to .carousel-content element
		 */
		const toggleAnimate = function() {
			$(document).trigger('animate-carousel');
			gcscroll(0);

			$carouselContent.toggleClass('is-animating');
		};

		/**
		 * setOrder change dynamically the order of all .carousel-item elements
		 */
		const setOrder = function(direction) {
			// initialize direction to change order
			if (direction === 'left') {
				direction = 1;
			} else if (direction === 'right') {
				direction = -1;
			}

			for (let i = 0, c = $items.length; i < c; i++) {
				if ($items[i].style.order) {
					// change order with direction
					const newValue = (parseInt($items[i].style.order, 10) + direction) % c;
					$($items[i]).css('order', newValue || c);

					$($items[i]).removeClass('is-next is-current is-prev');
				} else {
					// Initialize
					$($items[i]).css('order', i + 1);
				}
			}

			if ($items.length > 1) {
				for (let i = 0; i < $items.length; i++) {
					const order = $($items[i]).css('order');
					if (order == '3') {
						$($items[i]).addClass('is-next');
					} else if (order == '2') {
						$($items[i]).addClass('is-current');
					} else if (order == '1') {
						$($items[i]).addClass('is-prev');
					}
				}
			} else {
				$($items[0]).addClass('is-current');
			}
		};

		// Initiliaze order of .carousel-item
		setOrder();

		// TODO: clean this up
		const goNext = function(evt) {
			// remove reverse
			toggleReverse(true, 'remove');
			// Disable transition to instant change order
			toggleAnimate();
			// Change order of element
			// Current order 2 visible become order 3
			setOrder('right');
			// Enable transition to animate order 3 to order 2
			setTimeout(toggleAnimate, 50);
		};
		const goPrev = function(evt) {
			// add reverse
			toggleReverse(false, 'add');
			// Disable transition to instant change order
			toggleAnimate();
			// Change order of element
			// Current order 2 visible become order 1
			setOrder('left');
			// Enable transition to animate order 1 to order 2
			setTimeout(toggleAnimate, 50);
		};

		// POC

		const $nextTrigger = $('.flow-next', $t) || $t.closest('.flow-next');
		const $backTrigger = $('.flow-prev', $t) || $t.closest('.flow-prev');
		$nextTrigger.on('click', () => {
			goNext();
			// goDirection(true);
		});
		$backTrigger.on('click', () => {
			if ($items.length > 1) {
				goPrev();
				// goDirection(false);
			}
		});
	}
};

const init = $t => {
	const $flows = $t || $target;
	if ($flows.length === 0) return;
	$flows.forEach($el => {
		initWith($el);
	});
};

module.exports = {
	init,
	initWith
};
